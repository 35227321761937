.am-navbar {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    position: fixed;
    z-index: 9999;
    top: 0;
    width: 100%;
    color: #fff;
}
.divmagin{
    margin-top: 50px;
}
.submit {
    margin: 0 auto;
    margin-top: 100px;
    display: block;
    outline: 0 none;
    -webkit-appearance: none;
    padding: 0;
    text-align: center;
    font-size: 18px;
    height: 50px;
    line-height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    white-space: nowrap;
    color: #fff;
    background-color: #108EE9;
    border: 1px solid #108EE9;
    border-radius: 2px;
    width: 50%;
  }