.am-navbar {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    position: fixed;
    z-index: 9999;
    top: 0;
    width: 100%;
    color: #fff;
}
.divmagin{
    margin-top: 50px;
}

.result-example .spe {
    width: 60px;
    height: 60px;
  }
  .sub-title {
    color: #888;
    font-size: 14px;
    padding: 30px 0 18px 0;
    margin-left: 15px;
  }
  .djsClass{
    font-size: 20px;
    text-align: center;
    padding: 25px;
  }
  .zfcglogo {
    width: 200px;
    height:200px;
  }