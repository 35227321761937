.main{
    font-size: 16px;
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
    background: #ffffff;
    font-weight: 700;
    line-height: 30px;
}
.head{
    font-size: 16px;
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
    background: #ffffff;
    font-weight: 700;
    margin-top:45px;
    line-height: 30px;
}
.content{
    font-size: 15px;
    color:gray;
    padding-left: 15px;
    padding-right: 15px;
    line-height: 30px;
}