.am-navbar {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  top: 0;
  width: 100%;
  color: #fff;
}

.my-list {
  margin-top: 40px;

}
.root {
  height: 100%;
  background-color: #fff;
}

.navHeader {
  background-color: #fff;
}

.backHome {
  margin-top: 30px;
  text-align: center;
}

.backHome :global(a) {
  color: #666;
}

.formItem {
  position: relative;
  margin-bottom: 9px;
}

.formItem::after {
  content: '';
  position: absolute;
  background-color: #ddd;
  display: block;
  z-index: 1;
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transform: scaleY(0.5);
  transform: scaleY(0.5);
}

.input {
  height: 60px;
  width: 100%;
  padding: 2px 0;
  border: 0;
  color: #000;
  font-size: 17px;
  background-color: transparent;
}

.input::placeholder {
  color: #c0c0c0;
}

.formSubmit {
  margin-top: 27px;
}

.submit {
  display: block;
  outline: 0 none;
  -webkit-appearance: none;
  padding: 0;
  text-align: center;
  font-size: 18px;
  height: 50px;
  line-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: nowrap;
  color: #fff;
  background-color: #108EE9;
  border: 1px solid #108EE9;
  border-radius: 10px;
  width: 100%;
}

.error {
  color: red;
}
.scfj{
  padding-left:15px;
  font-size: 17px;
  padding-top:10px;
}
/* .popup-list .am-list-body {
  height: 210px;
  overflow: auto;
} */
.am-list-item .am-input-label.am-input-label-5{
  width: 95px !important;
}
.line{
  border-bottom: 1px solid rgba(23, 10, 11, 0.15);
  margin-left: 8px;
  margin-right: 8px;
}
.scfj{
  font-size: 15px;
}

input{
  text-align: right;
}
.date-picker-list .am-list-item .am-list-line .am-list-extra {
  flex-basis: initial;
}