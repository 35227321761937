.App {
  text-align: center;
  background-color: white;
}



/*logo */
.logo {
  width: 150px;
  height: 150px;
  margin-top: 50px;
}

.app-name {
  font-size: 20px;
  font-weight: bold;
}

.login {
  margin-top: 60px;
}

.loginButton {
  width: 80%;
  margin: 0 auto;
  margin-top: 40px;
}
.aclass{
  text-align: center;
  color: #ccc;
  padding-top: 150px;
}