.am-navbar{
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  top: 0;
  width: 100%;
  color: #fff;
}

.grid-css1{
  /* margin-top: 45px; */
  font-size: 16px;
  /* padding-top: 8px; */
  padding-left: 8px;
  background: #ffffff;
}
.grid-css-child{
  /* margin-top: 45px; */
  font-size: 16px;
  padding-top: 8px; 
  padding-left: 8px;
  background: #ffffff;
  font-weight: 700;
}