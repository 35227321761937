.ziti{
    font-size: 20px;;
}
.gs{
    color: red;
    padding: 10px;
    font-size: 18px;
}
.tishi{
    font-size: 16px;
    line-height: 25px;
}
.xianshi{
    color: blue;
}
.anniu{
    margin-right: 4px;
    color: white;
    /* background-color: white; */
    border: 1px solid white;
    font-size: 16px;
}
