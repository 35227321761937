.am-navbar {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    position: fixed;
    z-index: 9999;
    top: 0;
    width: 100%;
    color: #fff;
}
.my-list{
    margin-top: 45px;
}
.tips{
    color:red;
    padding-left: 15px;
    padding-top: 10px;
}
.am-input-extra{
    margin-right: 5px;
    max-height: 31px !important;
  }