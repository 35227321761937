html,body{
  height: 100%;
  font-family: "Microsoft-YaHei";
  color: #333;
  background-color: #fff;
}
*{
  box-sizing: border-box;
}

