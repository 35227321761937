.am-navbar {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  top: 0;
  width: 100%;
  color: #fff;
}

.my-list {
  margin-top: 40px;

}
.root {
  height: 100%;
  background-color: #fff;
}

.navHeader {
  background-color: #fff;
}

.backHome {
  margin-top: 30px;
  text-align: center;
}

.backHome :global(a) {
  color: #666;
}

.formItem {
  position: relative;
  margin-bottom: 9px;
}

.formItem::after {
  content: '';
  position: absolute;
  background-color: #ddd;
  display: block;
  z-index: 1;
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transform: scaleY(0.5);
  transform: scaleY(0.5);
}

.input {
  height: 60px;
  width: 100%;
  padding: 2px 0;
  border: 0;
  color: #000;
  font-size: 17px;
  background-color: transparent;
}

.input::placeholder {
  color: #c0c0c0;
}

.formSubmit {
  margin-top: 27px;
}

.submit {
  display: block;
  outline: 0 none;
  -webkit-appearance: none;
  padding: 0;
  text-align: center;
  font-size: 18px;
  height: 50px;
  line-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: nowrap;
  color: #fff;
  background-color: #108EE9;
  border: 1px solid #108EE9;
  border-radius: 30px;
  width: 50%;
}
.save {
  display: block;
  outline: 0 none;
  -webkit-appearance: none;
  padding: 0;
  text-align: center;
  font-size: 18px;
  height: 50px;
  line-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: nowrap;
  color: #fff;
  background-color: #FF6000;
  border: 1px solid #FF6000;
  border-radius: 30px;
  width: 50%;
}

.error {
  color: red;
}
.scfj{
  padding-left:15px;
  font-size: 17px;
  padding-top:10px;
}
/* .popup-list .am-list-body {
  height: 210px;
  overflow: auto;
} */
.am-list-item .am-input-label.am-input-label-5{
  width: 149 !important;
}
.line{
  border-bottom: 1px solid rgba(23, 10, 11, 0.15);
  margin-left: 8px;
  margin-right: 8px;
}
.scfj{
  font-size: 15px;
}

input{
  text-align: right;
}
.scfj{
  padding: 15px 15px 9px;
  font-size: 15px;
  
}
.btnMx {
  /* display: 'flex';
  justify-content: flex-end;
  position: 'relative';
  top: 0;
  right: 0; */
  text-align: right;
}
.btnMx .addBtn {
  width: auto;
  text-align:center;
  margin-right: 10px;
  border: none;
  color: #fff;
  background-color: #108EE9;
  border-radius: 30px;
}
.btnMx .delBtn {
  width: auto;
  text-align:center;
  margin-right: 10px;
  border: none;
  color: #fff;
  background-color: red;
  border-radius: 30px;
}
.additem {
  display: block;
  outline: 0 none;
  -webkit-appearance: none;
  padding: 0;
  text-align: center;
  font-size: 18px;
  height: 50px;
  line-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: nowrap;
  color: #fff;
  background-color: #108EE9;
  border: 1px solid #108EE9;
  border-radius: 2px;
  width: 50%;
  float:left;
}
.delitem {
  display: block;
  outline: 0 none;
  -webkit-appearance: none;
  padding: 0;
  text-align: center;
  margin-left: 20px;
  font-size: 18px;
  height: 50px;
  line-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: nowrap;
  color: #fff;
  background-color: red;
  border: 1px solid #108EE9;
  border-radius: 2px;
  width: 50%;
  text-align:center;
  float:center;
}
.mxitem {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}
.fixed-buttons {
  /* position: fixed;
  bottom: 0;
  left: 0; */
  background-color: #fff;
  margin:20px 0 20px 0;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}
.mxitemson {
display: flex;
justify-content: center;
align-items: center;
width: 50%;
}

.create-wrap {
  .am-list-item, .am-input-item, .am-list-item-middle {
      height: auto !important;
  }
  .am-input-label {
      word-wrap: wrap !important;
      word-break: break-all !important;
      white-space: normal !important;
  }
  .am-input-control {
      min-width: 90px !important;
  }
}

.am-list-item .am-list-line .am-list-extra {
  font-size: 14px !important;
 ;
}
.picker-list .am-list-item .am-list-line .am-list-extra {
  flex-basis: initial;
}

