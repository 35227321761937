.grid-css{
    margin-top: 45px;
    font-size: 16px;
    padding-top: 8px;
    padding-left: 8px;
    background: #ffffff;
}

.changeProfiel {
    margin: 0 5% 0 5%;
    text-align: center;
    width: 90%;
    padding-top: 20px;
}

.am-input-extra{
    max-height: 100px !important;
}