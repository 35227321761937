.am-navbar {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    position: fixed;
    z-index: 9999;
    top: 0;
    width: 100%;
    color: #fff;
}
.am-card {
    min-height: 75px;
}
.kf{
    margin-top: 50px;
}
.gs{
    font-size: 14px;
    color: rgb(243 75 98);
    text-align: left;
    padding: 5px;
    padding-left: 20px;
}